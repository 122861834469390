@use 'app/theme/antd-variables.scss' as *;

.closeIconWrapper {
	display: block;
	text-align: right;
	padding-right: $padding-md;

	:global(.anticon-close) {
		color: $neutral-7;
	}
}

.customDividerMedium {
	margin: $margin-md 0;
}

.customDividerLarge {
	margin: $margin-lg 0;
}

.formInnerContainer {
	padding: 0 $padding-lg;
}

.checkboxGroup {
	display: block;

	:global(.ant-checkbox-wrapper) {
		margin-inline-start: 0;
		padding-bottom: $padding-xs;
	}
}

.categoryInput {
	margin-bottom: 0;

	:global(.ant-row) {
		display: block;
	}

	:global {
		.ant-form-item-control-input-content {
			width: 100%;
			.ant-input-affix-wrapper {
				flex-wrap: wrap;
				flex-basis: 100%;
				align-items: center;

				&:before {
					content: '';
				}
			}

			.ant-input-prefix {
				max-width: 100%;
				flex-wrap: wrap;
				gap: 8px 0;
				.ant-tag {
					// IE11 fix since otherwise it doesn't respect max-width property
					min-width: 0%;
					max-width: 100%;

					.ant-typography-ellipsis-single-line {
						width: calc(100% - 16px);
					}
				}
			}

			.ant-input {
				flex: 1 1 40px;
			}
		}
	}
}
