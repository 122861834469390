@use 'app/theme/antd-variables.scss' as *;

.headerTop {
	padding: 0 $padding-lg $padding-lg $padding-lg;
}

.cannedService {
	background-color: $neutral-4;
	padding: $padding-lg;

	@media (max-width: $screen-md) {
		padding: 0;
	}

	.dragAndDropColumn {
		width: 30px;
		svg {
			position: relative;
			top: 7px;
		}
	}

	:global(.ant-empty) {
		display: none;
	}

	.content {
		padding: $padding-lg;
		padding-top: $padding-xs;
		background-color: $neutral-1;
	}

	strong {
		font-weight: 500;
	}

	:global(.ant-table-tbody > tr) {
		td {
			border-top-color: $neutral-4 !important;
			border-bottom-color: $neutral-4 !important;
			z-index: 1 !important;
		}
	}

	:global(.ant-form-item) {
		margin-bottom: 0;
	}

	:global(.ant-input-number-affix-wrapper .ant-input-number-prefix) {
		margin-inline-end: 0;
	}
}

.loader {
	padding-top: $padding-lg;
}
