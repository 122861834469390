@use './antd-variables.scss' as *;

// customizations to antd default styles

// tabs
.ant-tabs > .ant-tabs-nav {
	border-bottom: 1px solid $neutral-5;

	.ant-tabs-nav-wrap {
		margin-left: 28px;

		@media (max-width: $screen-xl - 1) {
			margin-left: 16px;
		}
	}
}

// layout
.ant-layout-content {
	background-color: $neutral-1;
}

// table
.ant-table {
	border: 1px solid $neutral-5;
}

.ant-table-container {
	z-index: 0;
}

.ant-table-row {
	cursor: pointer;
	vertical-align: top;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td,
.ant-table-wrapper .ant-table-tbody > tr > td {
	border-bottom: 1px solid $neutral-5;
}

.ant-table-wrapper .ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table.ant-table-small tfoot > tr > td {
	padding-left: $padding-md;
}

.ant-table-wrapper .ant-table-thead > tr > th {
	background: $neutral-2;
	font-weight: 500;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected > td {
	background: none;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row-selected:hover > td,
.ant-table-wrapper .ant-table-tbody > tr:hover {
	background: $neutral-2;
	cursor: initial;
}

.ant-table-wrapper table tr td.ant-table-selection-column {
	padding-inline-end: $padding-md;
	padding-inline-start: $padding-md;
}

.ant-table-cell::before {
	display: none;
}

// input
.ant-input-prefix {
	color: $neutral-8;
}

// tag
.ant-tag {
	margin-right: 0;
	border-color: $neutral-6;
	border-radius: 2px;
}
.ant-tag-blue {
	background-color: $blue-2;
	border-color: $blue-7;
	color: $blue-7;
}
.ant-tag-green {
	background-color: $green-1;
	border-color: $green-8;
	color: $green-8;
}

// pagination
.ant-pagination-item-active {
	border-color: $blue-7;
}
.ant-pagination-item-active a {
	color: $blue-7;
}
.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
	border-color: $blue-5;
}
.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
	color: $blue-5;
}

h4.ant-typography,
h5.ant-typography {
	margin-bottom: 0;
}

// modals
// modifies the modal header only when there's a close button present
.ant-modal-close + .ant-modal-header {
	// right padding is:
	// 34px from the left of the close (X) button + 8px of space between max title length and X
	padding-right: 42px;
}

// notifications
.ant-notification-notice {
	// right padding is:
	// 34px from the left of the close (X) button + 8px of space between max text length and X
	padding-right: 42px;
	border-radius: 0;
}

// forces table to bottom so that some UI elements don't appear above modals
.ant-table-container {
	z-index: 0;
}
