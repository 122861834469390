@use 'app/theme/antd-variables.scss' as *;

.customLink {
	padding-right: 16px;
	padding-left: 0px;
}

.modalSection {
	padding-bottom: 32px;
}

.inputs {
	padding-top: 8px;
}

.centerItem {
	padding: 0px 8px;
	line-height: 32px;
}

.tableHeader {
	line-height: 32px;
}

.sectionDesc {
	padding-top: 8px;
}

.innerContent {
	margin-top: 24px;
}

.maxQuantityInputs {
	margin-top: 8px;
}

.maxCostTitle {
	padding-top: 0px;
	margin-top: 0px;
}
