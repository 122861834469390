@use 'app/theme/antd-variables.scss' as *;

.CannedServicesLibrary {
	background-color: $neutral-4;
	padding: $padding-lg;
	min-height: 90vh;

	@media (max-width: $screen-md) {
		padding: 0;
	}

	:global(.ant-empty) {
		padding-top: 100px;
		height: 60vh;
	}

	:global(.ant-empty-image) {
		height: 120px;
	}
}

.content {
	padding: $padding-lg;

	@media (max-width: $screen-xl) {
		padding: $padding-md;
	}
}

.loader {
	padding-top: $padding-lg;
}
