@use 'app/theme/antd-variables.scss' as *;

.serviceTable {
	margin-bottom: $margin-md;

	.newItemRow {
		:global(.ant-input) {
			color: $neutral-7;
		}

		:global(.ant-input-number-affix-wrapper .ant-input-number-prefix) {
			color: $neutral-7;
		}

		:global(.ant-input-suffix) {
			color: $neutral-7;
		}

		:global(.ant-input-number .ant-input-number-input) {
			color: $neutral-7;
		}

		.errorMessage {
			color: $red-6;
		}
	}

	.customSummaryRow {
		background: $neutral-2;
	}

	.dragAndDropColumn {
		width: 30px;
		svg {
			position: relative;
			top: 7px;
		}
	}
}

.titleCell {
	width: 320px;
}

.titleColumn {
	min-width: 450px;
	padding-left: 12px;
}

.normalCell {
	width: 100%;
}

.normalColumn {
	width: 170px;
}

.taxColumn {
	width: 150px;
}

.taxCell {
	padding-left: $padding-xs;
}

.textCell {
	width: 100%;
	display: inline-block;
	padding-top: $padding-xss;
	padding-left: $padding-xs;
}

.actionColumn {
	text-align: right;
	.customLink {
		svg {
			padding-right: 0;
		}
	}
}

.trashButton {
	padding-left: 0px;
}

.deleteIcon {
	padding-top: $padding-xss;
}

.hasError {
	:global(.ant-input-number-group-addon) {
		color: $red-6;
		border: 1px solid $red-6;
		border-left: 0;
		background-color: transparent;
	}

	:global(.ant-input-number-affix-wrapper),
	:global(.ant-input-number) {
		border-right: none;
		box-shadow: none !important;
	}
}

.fixedPricePopup {
	max-width: 230px;
}
