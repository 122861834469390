@use 'app/theme/antd-variables.scss' as *;

.popover {
	width: 325px;
	padding-top: 0;

	:global(.ant-popover-inner) {
		padding: 0;
	}
}

.rightAligned {
	text-align: right;
}

.dropdownParent {
	width: 350px;
	height: 32px;
	padding: 0 10px;
	border: 1px solid $neutral-5;
	border-radius: 2px;
	position: relative;
	display: flex;
	align-items: center;
	transition: 0.2s ease-in-out;
	justify-content: space-between;

	&:hover {
		border-color: $blue-5;
		transition: 0.2s ease-in-out;
		cursor: pointer;
	}

	input {
		width: 100%;
		max-width: 160px;
		border: none;
		cursor: pointer;

		&:focus {
			outline: none;
		}
	}
}

.selectAll {
	padding: $padding-xs;

	span {
		color: $blue-6;
	}
}

.customDivider {
	margin: 0;
}

.shopList {
	flex-direction: column;
	padding: $padding-xs $padding-md 0 $padding-md;

	:global(.ant-checkbox-wrapper) {
		margin-inline-start: 0;
		padding-bottom: $padding-xs;
	}
}

.tagWrapper {
	display: flex;
	padding-right: 0;
}

.shopTag {
	height: 22px;
	min-width: 90px;
	margin: 0 $margin-xs;
	padding: $padding-xss - 2;
	background: $blue-2;
	border-radius: 2px;
	text-align: center;
}

.hidden {
	display: none;
}

.popConfirm {
	max-width: 290px;

	:global(.ant-popconfirm-message) {
		margin-bottom: 0;
	}

	:global(.ant-popconfirm-message-title) {
		font-weight: 400;
	}
}
