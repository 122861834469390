@use 'app/theme/antd-variables.scss' as *;

.filterBtn {
	border-color: $blue-5 !important;
	color: $blue-5 !important;

	:global(.ant-tag-blue) {
		color: $neutral-9;
		border: none;
	}
}

.popover {
	width: 520px;
	padding-top: 0;

	@media (max-width: $screen-sm-max) {
		width: calc(100% - #{$margin-xs} * 2);
		margin-left: $margin-xs;
		left: 0 !important;

		:global(.ant-popover-inner-content) {
			padding-right: 40px;
		}
	}

	:global(.ant-popover-inner) {
		padding: $padding-md 0;
	}

	:global(.ant-popover-arrow) {
		display: none;
	}
}
