@use 'app/theme/antd-variables.scss' as *;

.content {
	margin-bottom: 50px;

	> div {
		margin-bottom: $margin-md;
	}

	.createdAtUpdatedAt {
		margin-bottom: $margin-lg;
		margin-left: -$margin-xs;
	}
}

.rightAligned {
	text-align: right;
}

.customSelect {
	width: 175px;
}

:global(.ant-select-item-option) {
	max-width: 300px;
}

.deleteButtonColumn {
	display: flex;
	vertical-align: middle;
	justify-content: flex-end;
}
