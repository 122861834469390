@use 'app/theme/antd-variables.scss' as *;

.deleteButton {
	color: $red-6 !important;
	cursor: pointer;
	border: none;
	display: flex;
	box-shadow: none;

	padding-top: $padding-xss;
	padding-left: $padding-md;
	padding-right: $padding-md;
	margin-right: -$margin-md;

	.trashcanIcon {
		padding-right: $padding-xs;

		svg {
			vertical-align: middle;
		}

		path {
			fill: $red-6;
		}
	}
}
