@use 'app/theme/antd-variables.scss' as *;

.rightAligned {
	text-align: right;
}

.fixedPriceInput {
	width: 130px;
}

.errorMessage {
	color: $red-6;
}

.fixedPricePopup {
	max-width: 230px;
}

.marginBottom {
	margin-bottom: $margin-md;
}
