@use 'app/theme/antd-variables.scss' as *;

.footer {
	position: sticky;
	bottom: 0;
	z-index: 10;
	background-color: $neutral-1;
	padding: $padding-xs $padding-lg;
	display: flex;
	justify-content: flex-end;
	gap: $padding-xs;
	align-items: center;
}
