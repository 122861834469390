@use 'app/theme/antd-variables.scss' as *;

.totalPriceHeader {
	white-space: nowrap;
}

.titleColumn {
	width: 600px;
}

.table {
	border: solid 1px $neutral-4;
	:global(.ant-table) {
		min-height: 500px;
	}

	:global(.ant-empty-description) {
		white-space: pre-line;
	}
}

.customPageCount {
	position: relative;
	top: -43px;
	left: 16px;
}

.customTable {
	:global(.ant-empty-description) {
		white-space: pre-line;
	}

	:global(.ant-btn-default:disabled) {
		color: $neutral-6;
	}

	:global(.ant-pagination-simple-pager) {
		visibility: hidden;
		width: 100px;
	}

	:global(.ant-pagination) {
		display: flex;
		flex-wrap: wrap;
		row-gap: 8px;
		margin: $margin-lg 0;
		justify-content: center;
	}

	:global(
			.ant-pagination-disabled .ant-pagination-item-link,
			.ant-pagination-disabled:hover .ant-pagination-item-link
		) {
		color: $neutral-6;
		border-color: $neutral-5;
		cursor: not-allowed;
	}

	:global(.ant-btn-default:disabled) {
		cursor: not-allowed;
	}
}

.editLink {
	width: auto;
	display: inline-block;

	&:focus {
		outline: 1px solid;
	}
}

.editIcon {
	color: $blue-6;
	cursor: pointer;
	text-align: right;

	svg {
		width: 16px;
	}
}
