@use 'app/theme/antd-variables.scss' as *;

.headerTop {
	padding: $padding-lg;
	padding-bottom: 40px;
}

.title {
	margin: 0;
	text-transform: uppercase;
}
