@use 'app/theme/antd-variables.scss' as *;

.laborRates {
	background-color: $neutral-4;
	padding: $padding-lg;
	min-height: 90vh;

	@media (max-width: $screen-md) {
		padding: 0;
	}

	:global(.ant-empty) {
		padding-top: 100px;
		height: 60vh;
	}

	:global(.ant-empty-image) {
		height: 120px;
	}

	.laborRateTitle {
		margin-bottom: 8px;
	}

	.editableCell {
		display: block;
		padding: $padding-xss $padding-xs;
		border: 1px solid white;
	}

	.textCell {
		padding: $padding-xss $padding-xs;
		padding-right: 0;
		color: $neutral-7;
		display: block;
		border: 1px solid white;
	}

	.title {
		width: 320px;
	}

	.deleteIcon {
		width: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		div {
			height: 16px;
		}

		:hover {
			cursor: pointer;
		}
	}

	.description {
		margin-top: 0;
		margin-bottom: 0 !important;
	}

	:global(.ant-table-wrapper .ant-table.ant-table-small .ant-table-thead)
		> tr
		> th.actionColumn,
	:global(.ant-table-wrapper .ant-table.ant-table-small .ant-table-thead)
		> tr
		> th.excludeFromLaborMatrixColumn,
	:global(.ant-table-wrapper .ant-table.ant-table-small)
		tfoot
		> tr.tableSummaryRow
		> td {
		padding-left: $padding-xs;
	}

	.actionColumn {
		display: flex;
		justify-content: space-between;

		button {
			padding-left: 0;
		}
	}

	.excludeFromLaborMatrixColumn {
		label {
			padding-top: $padding-xss;
		}
	}

	.missingCannedService {
		display: flex;
		justify-content: center;
	}

	.helpCol {
		padding-top: 8px;
		margin-bottom: -10px;
	}
}

.customLink {
	display: grid;
	justify-content: center;
	align-items: center;
	align-self: center;
	padding-right: 20px;
}

.lowerCol {
	margin-bottom: -10px;
}

.addButton {
	margin-top: -16px !important;
}

.formItem {
	margin-bottom: 0;
}

.content {
	padding-left: 25%;
	padding-right: 25%;
	padding-top: $padding-lg;
	padding-bottom: 24px;

	@media (max-width: $screen-xl) {
		padding: $padding-md;
	}
}

.loader {
	padding-top: $padding-lg;
}

.standardRateContainer {
	display: flex;
	align-items: center;
}

.questionIcon {
	padding-left: $padding-xss;
	padding-top: $padding-xss + 2;
}
