// Copied over from theme.less, if changes are made to theme.less, this should be updated

// fonts
$font-family: Rubik, sans-serif;
// NOTE: use $font-size-base instead of font-size-md to match Antd less variables
$font-family: 'Rubik', sans-serif;
$font-size-base: 14px;
$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;

// Media queries breakpoints
// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

$screen-xs-max: ($screen-sm-min - 1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);
$screen-lg-max: ($screen-xl-min - 1px);
$screen-xl-max: ($screen-xxl-min - 1px);

// vertical paddings
$padding-lg: 24px; // containers
$padding-md: 16px; // small containers and buttons
$padding-sm: 12px; // Form controls and items
$padding-xs: 8px; // small items
$padding-xss: 4px; // more small

// vertical margins
$margin-lg: 24px; // containers
$margin-md: 16px; // small containers and buttons
$margin-sm: 12px; // Form controls and items
$margin-xs: 8px; // small items
$margin-xss: 4px; // more small

// colors
$neutral-1: #ffffff;
$neutral-2: #fafafa;
$neutral-3: #f5f5f5;
$neutral-4: #f0f0f0;
$neutral-5: #d9d9d9;
$neutral-6: #bfbfbf;
$neutral-7: #8c8c8c;
$neutral-8: #595959;
$neutral-9: #262626;
$neutral-10: #000000;

$blue-1: #f0fbff;
$blue-2: #e1f5fc;
$blue-3: #afdbf0;
$blue-4: #81c1e3;
$blue-5: #58a6d6;
$blue-6: #328ac9;
$blue-7: #2168a3;
$blue-8: #134a7d;
$blue-10: #041830;

$gold-1: #fffbe6;
$gold-3: #ffe58f;
$gold-6: #faad14;

$red-1: #ffebe6;
$red-4: #ff6554;
$red-5: #f53d40;
$red-6: #ed0802;
$red-7: #c70000;

$green-1: #f6ffed;
$green-3: #b7eb8f;
$green-4: #95de64;
$green-5: #73d13d;
$green-6: #52c41a;
$green-7: #389e0d;
$green-8: #237804;

$orange-1: #fff1e6;
$orange-3: #fcb083;
$orange-4: #f15922;

$bosch-red: #e20015;
$solera-purple: #32255e;
$primary-color: #3f97bc;
$error-color: $red-6;
$text-color: $neutral-9;

$enterprise-manager-purple: #6127b2;

// Collapse
$collapse-header-padding-extra: 40px;

$border-radius-base: 4px;
