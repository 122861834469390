@use 'app/theme/antd-variables.scss' as *;

.notification {
	.infoIcon {
		color: $blue-6;
	}

	.checkIcon {
		color: $green-7;
	}

	.warningIcon {
		color: $gold-6;
	}

	// Dark styles
	&.dark {
		background-color: $neutral-9;

		:global(.ant-notification-notice-message),
		:global(.ant-notification-notice-description),
		:global(.ant-notification-notice-close) {
			color: $neutral-1;
		}

		.infoIcon {
			color: $blue-3;
		}

		.checkIcon {
			color: $green-3;
		}

		.warningIcon {
			color: $gold-3;
		}
	}
}
