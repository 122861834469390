@use 'app/theme/antd-variables.scss' as *;

.categories {
	background-color: $neutral-4;
	padding: $padding-lg;
	min-height: 90vh;

	@media (max-width: $screen-md) {
		padding: 0;
	}

	:global(.ant-empty) {
		padding-top: 100px;
		height: 60vh;
	}

	:global(.ant-empty-image) {
		height: 120px;
	}

	.editableCell {
		display: block;
		padding: $padding-xss $padding-xs;
		border: 1px solid white;
	}

	.editableRow {
		background-color: blue;
	}

	.title {
		width: 320px;
	}

	.deleteIcon {
		width: 20px;
		display: flex;
		align-self: center;

		&:hover {
			cursor: pointer;
		}

		svg {
			vertical-align: middle;
		}
	}

	:global(.ant-table-wrapper .ant-table.ant-table-small .ant-table-thead)
		> tr
		> th.actionColumn,
	:global(.ant-table-wrapper .ant-table.ant-table-small)
		tfoot
		> tr.tableSummaryRow
		> td {
		padding-left: $padding-xs;
	}

	.actionColumn {
		display: flex;
		justify-content: space-between;

		button {
			padding-left: 0;
		}
	}
}

.popConfirmTitle {
	font-weight: 300;
}

.popConfirmUl {
	margin-top: 0;
}

.formItem {
	margin-bottom: 0;
}

.content {
	padding-left: 25%;
	padding-right: 25%;
	padding-top: $padding-lg;
	padding-bottom: 24px;

	@media (max-width: $screen-xl) {
		padding: $padding-md;
	}
}

.loader {
	padding-top: $padding-lg;
}

.popConfirm {
	:global(.ant-popconfirm-message) {
		margin-bottom: 0;
	}
}
