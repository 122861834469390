@use 'app/theme/antd-variables.scss' as *;

.summaryContainer {
	align-items: flex-end;
	flex-direction: column;
	border-radius: $border-radius-base;
	border: 1px solid $neutral-5;

	@media (min-width: $screen-xs) {
		width: 400px;
		margin-left: auto;
	}
}

.upperRow {
	width: 100%;
	font-weight: 500;
	padding: $padding-xs $padding-md;
	border-radius: $border-radius-base;
	background-color: $neutral-2;
	flex-direction: column;

	@media (min-width: $screen-xs) {
		flex-direction: row;
	}
}

.lowerRow {
	border-radius: $border-radius-base;
	padding: 0 $padding-md;
	align-items: center;

	.fixedPriceText {
		color: $neutral-7;
		margin-right: $margin-xs;
	}

	.fixedPriceValue {
		font-size: 24px;
	}
}
