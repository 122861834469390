@use 'app/theme/antd-variables.scss' as *;

@mixin custom-button($normal, $hover, $click) {
	color: $neutral-1;
	background-color: $normal;
	border-color: $normal;

	&:hover {
		color: $neutral-1;
		background-color: $hover;
		border-color: $hover;
	}

	&:focus {
		color: $neutral-1;
		background-color: $click;
		border-color: $click;
	}
}

:global(.ant-btn).multiline {
	white-space: pre-line;
	height: auto;
}

:global(.ant-btn).success {
	@include custom-button($green-7, $green-6, $green-8);
}

:global(.ant-btn).default {
	border-color: $neutral-5;
	&:hover,
	&:focus {
		background: $neutral-3;
	}
}

:global(.ant-btn-default).selected {
	color: $blue-7;
	border-color: $blue-7;
	background-color: $neutral-1;
}

:global(.ant-btn-primary).selected {
	@include custom-button($blue-7, $blue-5, $blue-8);
}
