@use 'app/theme/antd-variables.scss' as *;

.title {
	width: 325px;
}

.blueTitle {
	color: $blue-6;
}

.rightAligned {
	text-align: right;
	padding-top: $padding-xss;
}
