@use 'app/theme/antd-variables.scss' as vars;

.slider {
	position: relative;
}

.container {
	padding-top: 187px;
	padding-bottom: 20px;
	background-color: black;
	position: sticky;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.icon svg {
	height: 32px;
	width: auto;
	fill: white;
	vertical-align: middle;
}

.iconContainer {
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.65;

	&:focus-visible {
		outline: 2px solid currentColor;
	}

	&:global(.active) {
		opacity: 1;
		background: vars.$enterprise-manager-purple;
	}
}

.marginTop {
	margin-top: auto;
}
