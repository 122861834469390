@use 'app/theme/antd-variables.scss' as *;

.editableCell {
	display: block;
	border: 1px solid transparent;
	padding: $padding-xss $padding-xs;
}

:global(.ant-table-cell-row-hover) {
	.editableCell {
		border-color: $neutral-5;
		border-radius: 2px;
	}
}
