@use 'app/theme/antd-variables.scss' as vars;

.errorPage {
	height: 100vh;
	width: 100%;
}

.col {
	text-align: center;
}

.code {
	color: vars.$enterprise-manager-purple;
	font-size: 70px;
	display: block;
	font-weight: bold;
}

.message {
	font-size: 40px;
}
